import React, { useContext } from "react";
import { Toast } from "react-bootstrap";

import {ToasterContext} from "../../../stores/toaster";

import "./Toaster.scss";

function Toaster(): JSX.Element {

    const { state, dispatch } = useContext(ToasterContext);

    return (
        <Toast className={`toaster ${state.type}`} show={state.show || false} delay={4000} autohide onClose={() => dispatch({})}>
            <Toast.Body>{state.message}</Toast.Body>
        </Toast>
    );
}

export default Toaster;