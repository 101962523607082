import React from "react";
import {Button, Modal} from "react-bootstrap";

import ModalCancelProps from "./ModalCancel.types";
import "./ModalCancel.scss";

function ModalCancel({show, setShow, confirmCancel}: ModalCancelProps): JSX.Element {

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Body>Do you really discard the changes?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Close
                </Button>
                <Button className={"btn btn-color btn-color-blue"} onClick={() => confirmCancel()}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalCancel;