import React, {useCallback, useContext, useEffect, useState} from "react";
import {FaExclamationTriangle, FaHome} from "react-icons/all";
import {Button} from "react-bootstrap";

import {Loading, ModalCancel, PropertyEditor} from "../../components";
import {IProperty} from "../../domain";
import {Typologies} from "../../domain/enums";
import PropertyService from "../../service/PropertyService";
import {ToasterContext} from "../../stores/toaster";
import {LoadingContext} from "../../stores/loading";

import PropertyProps from "./Property.types";
import "./Property.scss";

function Property(props: PropertyProps): JSX.Element {
    const { dispatch } = useContext(ToasterContext);
    const { dispatch: loader } = useContext(LoadingContext);

    const initProperty: IProperty = {
        id: 0,
        ref: "",
        typology: Typologies.CHALET,
        description: "",
        price: 0,
        sold: false,
        bedrooms: 0,
        bathrooms: 0,
        plotSize: 0,
        buildSize: 0,
        latitude: null,
        longitude: null,
        images: [],
        town: null,
        logBurner: false,
        gas: false,
        water: false,
        electricity: false,
        sewage: false,
        internet: false,
        pool: false,
        busRoute: false,
        schoolRoute: false,
        garage: false,
        airConditioning: false,
    };

    const [myProperty, setMyProperty] = useState<IProperty>(props?.location?.state?.property || initProperty);
    const [showDiscardModal, setShowDiscardModal] = useState<boolean>(false);

    const getProperty = useCallback(async (id: number) => {
        try {
            const property: IProperty = await PropertyService.getPropertyById(id);
            setMyProperty(property);
        } catch (error) {
            dispatch({message: `${error.message || error.error}`, type: "error"});
        }
    }, [dispatch]);

    const confirmCancel = (): void => {
        setShowDiscardModal(false);
        props.history.push("/properties");
    };

    const isValidProperty = (): string => {
        if (!myProperty.images.length) return "The property must have at least one photo!";
        if (!myProperty.typology) return "Select a typology!";
        if (!myProperty.ref) return "Reference is required!";
        if (!myProperty.price) return "Price is required!";
        if (!myProperty.description) return "Description is required!";
        if (!myProperty.bedrooms) return "Bedrooms are required!";
        if (!myProperty.bathrooms) return "Bathrooms are required!";
        if (!myProperty.plotSize) return "Plot Size is required!";
        if (!myProperty.buildSize) return "Build Size is required!";
        if (!myProperty.town) return "Select a town!";
        return "";
    };

    const upsertProperty = async () => {
        try {
            myProperty.townId = myProperty.town.id;
            if (myProperty.id) await PropertyService.updateProperty(myProperty);
            else await PropertyService.createProperty(myProperty);
            props.history.push("/properties");
        } catch (error) {
            dispatch({message: `${error.message || error.error}`, type: "error"});
        }
    };

    useEffect(() => {
        if (props.match.params.id) {
            loader({ show: true });
            getProperty(props.match.params.id).then(() => loader({ show: false }));
        }
    }, [props.match.params.id, getProperty, loader]);

    return (
        myProperty ?
            <div id={"property-page"}>
                <Loading />
                <ModalCancel show={showDiscardModal} setShow={setShowDiscardModal} confirmCancel={confirmCancel}/>
                <h1><FaHome /> {myProperty.id ? `Editing ${myProperty.ref}` : "New property"}</h1>
                <br />
                <PropertyEditor property={myProperty} setProperty={setMyProperty}/>
                <br/>
                {isValidProperty().length ? <p className={"danger-info"}><FaExclamationTriangle /> {isValidProperty()}</p> : ''}
                <br/>
                <div className={"footer-btn"}>
                    <Button className="btn btn-color btn-color-blue" disabled={isValidProperty().length > 0} onClick={upsertProperty}>
                        {myProperty.id ? "Update Property" : "Create Property"}
                    </Button>
                    <Button variant="secondary" onClick={() => setShowDiscardModal(true)}>
                        Cancel
                    </Button>
                </div>
            </div>
        : <div />
    );
}

export default Property;