import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {BsFillTrashFill, FaPlusCircle} from "react-icons/all";

import {Loading, Pagination, PropertyTable} from "../../components";
import {ICriteria, IPage} from "../../domain";
import {OrderBy, SortBy} from "../../domain/enums";
import PropertyService from "../../service/PropertyService";
import {ToasterContext} from "../../stores/toaster";
import {LoadingContext} from "../../stores/loading";
import {transformCriteriaToQueryParam} from "../../utils/utils";

import PropertiesProps from "./Properties.types";
import "./Properties.scss";


function Properties(props: PropertiesProps): JSX.Element {
    const { dispatch } = useContext(ToasterContext);
    const { dispatch: loader } = useContext(LoadingContext);

    const initPage: IPage = {
        currentPage: 1,
        totalPages: 1,
        totalProperties: 0,
        size: 10,
        properties: []
    };
    const initCriteria: ICriteria = {
        page: 1,
        size: 10,
        field: SortBy.UPDATED,
        order: OrderBy.DESC
    };

    const [criteria, setCriteria] = useState<ICriteria>(initCriteria);
    const [page, setPage] = useState<IPage>(initPage);

    const getProperties = useCallback(async () => {
        window.scrollTo(0, 0);
        try {
            const page: IPage = await PropertyService.getProperties(transformCriteriaToQueryParam(criteria));
            setPage(page);
        } catch (error) {
            dispatch({message: `${error.message || error.error}`, type: "error"});
        }
    }, [dispatch, criteria]);

    useEffect(() => {
        loader({show: true});
        getProperties().then(() => loader({show: false}));
    }, [getProperties, loader]);

    return (
        <div id={"properties-page"}>
            <Loading />
            <div className={"title-container"}>
                <h1>Properties</h1>
                <Button className={"btn btn-link btn-link-blue"} onClick={() => props.history.push("/property/create")}><FaPlusCircle/></Button>
            </div>
            <div className={"search-container"}>
                <Form inline>
                    <Form.Label>Find by reference:</Form.Label>
                    <Form.Control
                        id="by-ref"
                        placeholder="EX: L103"
                        value={criteria?.ref}
                        onChange={e => setCriteria({...criteria, ref: e.currentTarget.value || undefined, page: 1 })}
                    />
                </Form>
                <Button className={"btn btn-color btn-color-black"} onClick={() => setCriteria(initCriteria)}>
                    <BsFillTrashFill /> FILTERS
                </Button>
            </div>
            <PropertyTable properties={page?.properties || []} criteria={criteria} setCriteria={setCriteria} {...props} getProperties={getProperties}/>
            <Pagination currentPage={page.currentPage} totalPages={page.totalPages} setCriteria={page => setCriteria({...criteria, page})}/>
        </div>
    );
}

export default Properties;