import Axios from "axios";
import { handleError } from "./CommonService";

const BASE_URL: string = `${process.env.REACT_APP_AUTHENTICATE_URL}`;
const endpoints = {
    login: () => `${BASE_URL}/authenticate`,
};

const login = (username: string, password: string): Promise<string> => {
    return Axios.post(endpoints.login(), {username, password})
        .then(res => {
            if (!res.data) throw new Error("Invalid credentials!");
            return res.data;
        })
        .catch(handleError);
};

const exportedObject = {
    login,
}
export default exportedObject;
