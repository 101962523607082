import React from "react";
import {Button, Modal} from "react-bootstrap";

import Amenities from "./Amenities/Amenities";
import HouseLayout from "./HouseLayout/HouseLayout";
import Location from "./Location/Location";
import MainInfo from "./MainInfo/MainInfo";
import Pictures from "./Pictures/Pictures";
import Status from "./Status/Status";

import {ModalViewerProps} from "./ModalViewer.types";
import "./PropertyEditor.scss";

function ModalViewer({property, showModal, setShowModal, ...props}: ModalViewerProps): JSX.Element {
    return (
        <Modal id="viewer" show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{property.ref} Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id={"property-editor"}>
                    <Pictures property={property} viewer={true} {...props}/><br/>
                    <br />
                    <Status property={property} viewer={true} {...props}/>
                    <br/>
                    <MainInfo property={property} viewer={true} {...props}/>
                    <br/>
                    <HouseLayout property={property} viewer={true} {...props}/>
                    <br/>
                    <Location property={property} viewer={true} {...props}/>
                    <br/>
                    <Amenities property={property} viewer={true} {...props}/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className={"btn btn-color btn-color-blue"} onClick={() => setShowModal(false)}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalViewer;