import Axios from "axios";
import { ITown } from "../domain";
import { getHeaders, handleError } from "./CommonService";

const BASE_URL: string = `${process.env.REACT_APP_CMS_URL}`;
const SERVICE_NAME: string = "town";
const endpoints = {
    createAndGetAllTowns: () => `${BASE_URL}/${SERVICE_NAME}`,
};

const createTown = (town: ITown): Promise<ITown> => {
    return Axios.post(endpoints.createAndGetAllTowns(), town, {headers: getHeaders()})
        .then(res => res.data)
        .catch(handleError);
};

const getAllTowns = (): Promise<ITown[]> => {
    return Axios.get(endpoints.createAndGetAllTowns(), {headers: getHeaders()})
        .then(res => res.data)
        .catch(handleError);
};

const exportedObject = {
    createTown,
    getAllTowns,
};
export default exportedObject;
