import Axios from "axios";
import { IProperty, IPage } from "../domain";
import { getHeaders, handleError } from "./CommonService";

const BASE_URL: string = `${process.env.REACT_APP_CMS_URL}`;
const SERVICE_NAME: string = "property";
const endpoints = {
    getProperty: (id: number) => `${BASE_URL}/${SERVICE_NAME}/${id}`,
    upsertProperty: () => `${BASE_URL}/${SERVICE_NAME}`,
    getPropertiesByFilters: (filters: string) => `${BASE_URL}/${SERVICE_NAME}${filters}`,
};

const getPropertyById = (propertyId: number): Promise<IProperty> => {
    return Axios.get(endpoints.getProperty(propertyId), {headers: getHeaders()})
        .then(res => res.data)
        .catch(handleError);
};

const getPropertyByReference = (reference: string): Promise<IProperty | undefined> => {
  return Axios.get(endpoints.getPropertiesByFilters(`?ref=${reference}`), {headers: getHeaders()})
    .then(res => res.data.properties[0])
    .catch(handleError);
};

const deleteProperty = (propertyId: number): Promise<IProperty> => {
    return Axios.delete(endpoints.getProperty(propertyId), {headers: getHeaders()})
        .then(res => res.data)
        .catch(handleError);
};

const createProperty = (property: IProperty): Promise<IProperty> => {
    return Axios.post(endpoints.upsertProperty(), property, {headers: getHeaders()})
        .then(res => res.data)
        .catch(handleError);
};

const updateProperty = (property: IProperty): Promise<IProperty> => {
    return Axios.put(endpoints.upsertProperty(), property, {headers: getHeaders()})
        .then(res => res.data)
        .catch(handleError);
};

const getProperties = (filters: string): Promise<IPage> => {
  return Axios.get(endpoints.getPropertiesByFilters(filters), {headers: getHeaders()})
    .then(res => res.data)
    .catch(handleError);
};

const exportedObject = {
    getPropertyById,
    getPropertyByReference,
    createProperty,
    updateProperty,
    deleteProperty,
    getProperties
};
export default exportedObject;
