import 'bootstrap/dist/css/bootstrap.min.css';
import './style/global.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import {ToasterProvider} from "./stores/toaster";
import {LoadingProvider} from "./stores/loading";

const app = (
    <LoadingProvider>
        <ToasterProvider>
            <App />
        </ToasterProvider>
    </LoadingProvider>
);
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
