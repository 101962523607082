import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {FaPlusCircle} from "react-icons/all";

import {CreateModal, Loading, TownList} from "../../components";
import {ITown} from "../../domain";
import {ToasterContext} from "../../stores/toaster";
import {LoadingContext} from "../../stores/loading";
import TownService from "../../service/TownService";

import TownsProps from "./Towns.types";
import "./Towns.scss";

function Towns(props: TownsProps): JSX.Element {
    const { dispatch } = useContext(ToasterContext);
    const { dispatch: loader } = useContext(LoadingContext);

    const [towns, setTowns] = useState<ITown[]>([]);
    const [showCreateAlert, setShowCreateAlert] = useState<boolean>(false);

    const getTowns = useCallback(async () => {
        try {
            const towns: ITown[] = await TownService.getAllTowns();
            setTowns(towns);
        } catch (error) {
            dispatch({message: `${error.message || error.error}`, type: "error"});
            setTowns([]);
        }
    }, [dispatch]);

    useEffect(() => {
        loader({ show: true });
        getTowns().then(() => loader({ show: false }));
    }, [getTowns, loader, showCreateAlert]);

    useEffect(() => {}, [towns]);

    return (
        <div id={"towns-page"}>
            <Loading />
            <CreateModal show={showCreateAlert} setShow={setShowCreateAlert} getTowns={getTowns}/>
            <div className={"title-container"}>
                <h1>Towns</h1>
                <Button className={"btn btn-link btn-link-blue"} onClick={() => setShowCreateAlert(true)}><FaPlusCircle/></Button>
            </div>
            <TownList towns={towns} setShowCreateAlert={setShowCreateAlert}/>
        </div>
    );
}

export default Towns;