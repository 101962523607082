import React from "react";
import {FaPlusCircle} from "react-icons/all";
import {Button, Table} from "react-bootstrap";

import TownTableProps from "./TownTable.types";
import "./TownTable.scss";

function TownTable({towns, setShowCreateAlert}: TownTableProps): JSX.Element {

    return (
        <div id={"town-list"}>
            <div id={"town-list"}>
                <Table responsive hover>
                    <thead>
                    <tr>
                        <th>NAME</th>
                        <th>LONGITUDE</th>
                        <th>LATITUDE</th>
                    </tr>
                    </thead>
                    <tbody>
                    {towns.map((town, i) => (
                        <tr key={i}>
                            <td>{town.name}</td>
                            <td>{town?.longitude || 0}</td>
                            <td>{town?.latitude || 0}</td>
                        </tr>
                    ))}
                    <tr className={"tr-btn"}>
                        <td>
                            <Button
                                className={"btn btn-color btn-color-green"}
                                onClick={() => setShowCreateAlert(true)}
                            >
                                <FaPlusCircle/> New Town
                            </Button>
                        </td>
                        <td/>
                        <td/>
                    </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default TownTable;