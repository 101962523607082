import React, {useContext, useState} from "react";
import {FaExclamationTriangle} from "react-icons/all";
import {Button, Form, Modal} from "react-bootstrap";

import {ToasterContext} from "../../../stores/toaster";
import {ITown} from "../../../domain";
import TownService from "../../../service/TownService";

import CreateModalProps from "./CreateModal.types";
import "./CreateModal.scss";

function CreateModal({show, setShow, getTowns}: CreateModalProps): JSX.Element {
    const { dispatch } = useContext(ToasterContext);
    const initTown: ITown = {
        name: "",
        longitude: undefined,
        latitude: undefined
    };

    const [town, setTown] = useState<ITown>(initTown);

    function isValidForm(): string {
        if (!town.name) return "The name is required!";
        if (town.latitude === undefined) return "The latitude is required!";
        if (town.longitude === undefined) return "The longitude is required!";
        else return "";
    }

    const handleClose = () => {
        setTown(initTown);
        setShow(false);
    };

    const createTown = async () => {
        setShow(false);
        try {
            await TownService.createTown(town);
            dispatch({message: `Created Town: ${town.name} ${town.longitude} ${town.latitude}`, type:"success" });
            getTowns();
        } catch (error) {
            dispatch({message: `${error.message || error.error}`, type: "error"});
        }
        setTown(initTown);
    };

    return (
        <Modal show={show} onHide={handleClose} id={"modal-create-town"}>
            <Modal.Header closeButton>
                <Modal.Title>Creating Town</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form inline>
                    <Form.Row>
                        <Form.Label>Town name:</Form.Label>
                        <Form.Control
                            id="town-name"
                            placeholder="Lliria"
                            value={town.name}
                            onChange={e => setTown({ ...town, name: e.currentTarget.value })}
                        />
                    </Form.Row>
                    <br />
                    <Form.Row>
                        <Form.Label>Latitude:</Form.Label>
                        <Form.Control
                            id="town-lat"
                            type="number"
                            placeholder="0"
                            value={town.latitude}
                            onChange={e => setTown({ ...town, latitude: parseInt(e.currentTarget.value, 10) || 0 })}
                        />
                    </Form.Row>
                    <br/>
                    <Form.Row>
                        <Form.Label>Longitude:</Form.Label>
                        <Form.Control
                            id="town-long"
                            type="number"
                            placeholder="0"
                            value={town.longitude}
                            onChange={e => setTown({ ...town, longitude: parseInt(e.currentTarget.value, 10) || 0 })}
                        />
                    </Form.Row>
                </Form>
                {isValidForm().length ? <p className={"danger-info"}><FaExclamationTriangle /> {isValidForm()}</p> : ''}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button className={"btn btn-color btn-color-blue"} onClick={createTown} disabled={isValidForm().length > 0}>
                    Create
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CreateModal;