import React from "react";
import { Button } from "react-bootstrap";
import {FaChevronLeft, FaChevronRight} from "react-icons/all";

import PaginationProps from "./Pagination.types";
import "./Pagination.scss";


function Pagination({ currentPage, totalPages, setCriteria }: PaginationProps): JSX.Element {
    function setPage(p: number): void {
        if (p > totalPages) p = totalPages;
        if (p <= 0) p = 1;
        setCriteria(p);
    }

    function buttonsToShow(): number[] {
        if (totalPages > 5) {
            if (currentPage < 5) return [1, 2, 3, 4, 5, 0, totalPages];
            else if (currentPage > totalPages - 4) return [1, 0, totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
            else return [1, 0, currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, 0, totalPages];
        } else {
            const pages: number[] = [];
            for (let i:number = 1; i <= totalPages; i++) {
                pages.push(i);
            }
            return pages;
        }

    }

    function renderPagination() {
        const pages: number[] = buttonsToShow();
        return (
            pages.map((p, i) => {
                if (p === 0) return <p key={i}>...</p>;
                else return <Button
                    key={i}
                    className={`btn btn-rounded ${currentPage === p ? " btn-color btn-color-blue" : "btn-bordered btn-bordered-black" }`}
                    onClick={() => setPage(p)}
                >
                    {p}
                </Button>;
            })
        );
    }

    return (
        <div id={"pagination"}>
            <Button className={"btn btn-link btn-link blue btn-arrows"} onClick={() => setPage(currentPage - 1)}><FaChevronLeft /></Button>
            {renderPagination()}
            <Button className={"btn btn-link btn-link blue btn-arrows"} onClick={() => setPage(currentPage + 1)}><FaChevronRight  /></Button>
        </div>
    );
}

export default Pagination;