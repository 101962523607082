import React from "react";
import {Col, Form, Row} from "react-bootstrap";

import PropertyEditorProps from "../PropertyEditor.types";
import "./Amenities.scss";

function Amenities({property, setProperty, viewer}: PropertyEditorProps): JSX.Element {
    return (
        <div id={"amenities"}>
            <h3>Amenities</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={6} md={3}>
                    <Form.Check disabled={viewer} custom checked={property?.gas} onChange={() => setProperty({...property, gas: !property.gas})} label="Gas" type="checkbox" id={`gas`}/>
                    <Form.Check disabled={viewer} custom checked={property?.water} onChange={() => setProperty({...property, water: !property.water})} label="Water" type="checkbox" id={`water`}/>
                    <Form.Check disabled={viewer} custom checked={property?.electricity} onChange={() => setProperty({...property, electricity: !property.electricity})} label="Electricity" type="checkbox" id={`electricity`}/>
                    <Form.Check disabled={viewer} custom checked={property?.pool} onChange={() => setProperty({...property, pool: !property.pool})} label="Swimming Pool" type="checkbox" id={`pool`}/>
                    <Form.Check disabled={viewer} custom checked={property?.internet} onChange={() => setProperty({...property, internet: !property.internet})} label="Internet" type="checkbox" id={`internet`}/>
                    <Form.Check disabled={viewer} custom checked={property?.airConditioning} onChange={() => setProperty({...property, airConditioning: !property.airConditioning})} label="Air Conditioning" type="checkbox" id={`airConditioning`}/>
                </Col>
                <Col xs={12} sm={6} md={3}>
                    <Form.Check disabled={viewer} custom checked={property?.sewage} onChange={() => setProperty({...property, sewage: !property.sewage})} label="Sewage" type="checkbox" id={`sewage`}/>
                    <Form.Check disabled={viewer} custom checked={property?.logBurner} onChange={() => setProperty({...property, logBurner: !property.logBurner})} label="Log Burner" type="checkbox" id={`logBurner`}/>
                    <Form.Check disabled={viewer} custom checked={property?.busRoute} onChange={() => setProperty({...property, busRoute: !property.busRoute})} label="Bus Route" type="checkbox" id={`busRoute`}/>
                    <Form.Check disabled={viewer} custom checked={property?.schoolRoute} onChange={() => setProperty({...property, schoolRoute: !property.schoolRoute})} label="School Route" type="checkbox" id={`schoolRoute`}/>
                    <Form.Check disabled={viewer} custom checked={property?.garage} onChange={() => setProperty({...property, garage: !property.garage})} label="Garage" type="checkbox" id={`garage`}/>
                </Col>
            </Row>
        </div>
    );
}

export default Amenities;