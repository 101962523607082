import React from "react";
import { Link } from 'react-router-dom';
import {BsFillHouseFill, AiFillDashboard, BiExit, RiMapPinLine} from "react-icons/all";
import {Button} from "react-bootstrap";

import {IMenu} from "../../domain";
import {removeTokenCookie} from "../../utils/cookies";

import "./Menu.scss";

function Menu({ location }: any) {
    const menus: IMenu[] = [
        {
            id: 1,
            name: "Dashboard",
            href: "/",
            icon: <AiFillDashboard/>,
            isActive: location?.pathname === "/",
        }, {
            id: 2,
            name: "Properties",
            href: "/properties",
            icon: <BsFillHouseFill/>,
            isActive: location?.pathname === "/",
        }, {
            id: 3,
            name: "Towns",
            href: "/towns",
            icon: <RiMapPinLine/>,
            isActive: location?.pathname === "/",
        }
    ];

    return (
        <div id="menu-container">
            <ul className="menu-list">
                {menus.map(menu => (
                    <li key={menu.id} className={`menu ${menu.isActive ? 'active' : ''}`}>
                        <Link to={menu.href} className={"menu-text"}>
                            {menu.icon}
                            <span>{menu.name}</span>
                        </Link>
                    </li>
                ))}
            </ul>
            <ul className="menu-list logout">
                <li className={"menu"}>
                    <Button className={"btn btn-link btn-link-blue menu-text"} onClick={() => removeTokenCookie()}>
                        <BiExit />
                        <span>Log out</span>
                    </Button>
                </li>
            </ul>
        </div>
    );
}

export default Menu;