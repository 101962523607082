import React from "react";

import Amenities from "./Amenities/Amenities";
import HouseLayout from "./HouseLayout/HouseLayout";
import Location from "./Location/Location";
import MainInfo from "./MainInfo/MainInfo";
import Pictures from "./Pictures/Pictures";
import Status from "./Status/Status";

import PropertyEditorProps from "./PropertyEditor.types";
import "./PropertyEditor.scss";

function PropertyEditor({property, ...props}: PropertyEditorProps): JSX.Element {
    return (
        <div id={"property-editor"}>
            <Pictures property={property} {...props}/><br/>
            <br />
            <Status property={property} {...props}/>
            <br/>
            <MainInfo property={property} {...props}/>
            <br/>
            <HouseLayout property={property} {...props}/>
            <br/>
            <Location property={property} {...props}/>
            <br/>
            <Amenities property={property} {...props}/>
        </div>
    );
}

export default PropertyEditor;