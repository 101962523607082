import React from "react";
import {Image} from "react-bootstrap";

import DashboardProps from "./Dashboard.types";
import "./Dashboard.scss";

function Dashboard(props: DashboardProps): JSX.Element {

    return (
        <div id={"dashboard-page"}>
            <h1>Welcome to admin panel</h1>
            <br />
            <Image src={"./img/logos/Color.png"} />
        </div>
    );
}

export default Dashboard;