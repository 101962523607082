import React, {Suspense, useEffect} from "react";
import { Redirect } from "react-router-dom";

import Toaster from "../../components/common/Toaster/Toaster";
import Footer from "../Footer/Footer";
import Menu from "../Menu/Menu";

import "./Container.scss";
import {getTokenCookie} from "../../utils/cookies";

function Container(props: any) {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <Suspense fallback={<p>Loading..</p>}>
            {getTokenCookie() ?
                <main id={"container"}>
                    <Menu {...props}/>
                    <Toaster/>
                    <div className={"page"}>
                        {props.children}
                    </div>
                    <Footer/>
                </main>
            : <Redirect to={"/login"}/>}
        </Suspense>
    );
}

export default Container;