import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Container from "./layout/Container/Container";
import {Dashboard, Login, Properties, Property, Towns} from "./pages";

function App() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
          <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/" render={(props) => <Container children={<Dashboard {...props} />} {...props} />} />
              <Route exact path="/properties" render={(props) => <Container children={<Properties {...props}/>} {...props} />} />
              <Route exact path="/property/create" render={(props) => <Container children={<Property {...props} />} {...props} />} />
              <Route exact path="/property/edit/:id" render={(props) => <Container children={<Property {...props} />} {...props} />} />
              <Route exact path="/towns" render={(props) => <Container children={<Towns {...props} />} {...props} />} />
          </Switch>
      </Router>
  );
}

export default App;
