import React from "react";

import "./Footer.scss";

function Footer() {
    return (
        <div className={"footer"}>
            <div className={"copyright-footer"}>
                <p>© Turia Properties</p>
            </div>
        </div>
    );
}

export default Footer;