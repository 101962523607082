import React, {useContext} from "react";
import {FaPlus} from "react-icons/all";

import ImagesPropertyGrid from "./Grid/ImagesPropertyGrid";
import ImageListEditor from "./List/ImageListEditor";
import {ToasterContext} from "../../../../stores/toaster";
import StorageService from "../../../../service/StorageService";


import PropertyEditorProps from "../PropertyEditor.types";
import "./Pictures.scss";

function Pictures({property, setProperty, viewer}: PropertyEditorProps): JSX.Element {
    const { dispatch } = useContext(ToasterContext);

    const setImages = (images: string[]): void => {
        setProperty({...property, images});
    };

    const getBase64 = (file: any, cb: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const addImages = (event: any): void => {
        let images = property.images;
        const files = event.target.files;

        for (const file of files) {
            getBase64(file, async (result: string) => {
                try {
                    const image: string = await StorageService.uploadImage({ base64EncodedFile: result });
                    images.push(image);
                    setImages(images);
                } catch (error) {
                    dispatch({message: `${error.message || error.error}`, type: "error"});
                }
            });
        }


        event.target.value = "";
    };

    return (
        <div id={"images"}>
            <h3>Pictures</h3>
            <hr/>
            <div>
                <label>WEBSITE PREVIEW</label>
                <ImagesPropertyGrid images={property.images}/>
            </div>
            <br />
            {!viewer ?
                <div>
                    <label>UPLOADED IMAGES</label>
                    <div className={"uploaded-images"}>
                        {property.images.length
                            ? <ImageListEditor images={property.images} setImages={setImages}/>
                            : ''
                        }
                        <div>
                            <input type="file" accept={"image/*"} name="file" id="file" multiple className="input-file" onChange={addImages}/>
                            <label htmlFor="file" className="btn btn-bordered btn-bordered-green">
                                <FaPlus/>
                            </label>
                        </div>
                    </div>
                </div>
            : <div/>
            }
        </div>
    );
}

export default Pictures;