import React from "react";
import {Col, Dropdown, DropdownButton, Form, InputGroup} from "react-bootstrap";

import {Typologies} from "../../../../domain/enums";

import PropertyEditorProps from "../PropertyEditor.types";
import "./MainInfo.scss";

function MainInfo({property, setProperty, viewer}: PropertyEditorProps): JSX.Element {

    return (
        <div id={"main-info"}>
            <h3>Main Info</h3>
            <hr/>
            <Form>
                <Form.Row>
                    <Form.Group as={Col} xs={"12"} sm={"6"} md={"4"}>
                        <Form.Label>TYPOLOGY</Form.Label>
                        <DropdownButton disabled={viewer} id="dropdown-basic-main-info" title={property?.typology|| "None"}>
                            <Dropdown.Item
                                className={Typologies.CHALET === property?.typology ? "active" : ""}
                                onClick={() => setProperty({...property, typology: Typologies.CHALET})}
                            >
                                CHALET
                            </Dropdown.Item>
                            <Dropdown.Item
                                className={Typologies.FLAT === property?.typology ? "active" : ""}
                                onClick={() => setProperty({...property, typology: Typologies.FLAT})}
                            >
                                FLAT
                            </Dropdown.Item>
                        </DropdownButton>
                    </Form.Group>
                    <Form.Group as={Col} xs={"12"} sm={"6"} md={"4"}>
                        <Form.Label>REFERENCE</Form.Label>
                        <Form.Control
                            disabled={viewer}
                            type="string"
                            min={0}
                            name="reference"
                            value={property?.ref}
                            onChange={e => setProperty({...property, ref: e.currentTarget.value.trim()})}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={"12"} sm={"6"} md={"4"}>
                        <Form.Label>PRICE</Form.Label>
                        <InputGroup className="mb-2">
                            <Form.Control
                                disabled={viewer}
                                type="number"
                                min={0}
                                name="price"
                                value={property?.price}
                                onChange={e => setProperty({...property, price: parseInt(e.currentTarget.value, 10)})}
                            />
                            <InputGroup.Prepend>
                                <InputGroup.Text>€</InputGroup.Text>
                            </InputGroup.Prepend>
                        </InputGroup>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} xs={"12"}>
                        <Form.Label>DESCRIPTION</Form.Label>
                        <Form.Control disabled={viewer} as="textarea" rows={10} value={property.description} onChange={e => setProperty({...property, description: e.currentTarget.value})} />
                    </Form.Group>
                </Form.Row>
            </Form>
        </div>
    );
}

export default MainInfo;