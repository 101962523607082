import React, {useContext, useState} from "react";
import {Image, Form, Button} from 'react-bootstrap';

import {Toaster} from "../../components/common";
import {setTokenCookie} from "../../utils/cookies";
import {ToasterContext} from "../../stores/toaster";
import AuthService from "../../service/AuthService";

import LoginProps from "./Login.types";
import "./Login.scss";

function Login(props: LoginProps): JSX.Element {
    const { dispatch } = useContext(ToasterContext);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");


    const login = async () => {
        try {
            const token = await AuthService.login(username, password);
            dispatch({message: "Login successfully!", type: "success"});
            setTokenCookie(token);
            props.history.push("/");
        } catch (error) {
            dispatch({message: `${error.message || error.error}`, type: "error"});
        }
    };

    const handleKeyUp = (e: any) => {
        if (e.key === "Enter") login();
    };

    return (
        <div id={"login"}>
            <Toaster/>
            <div className={"image-container"} style={{backgroundImage: `url('img/test/search1.jpg')`}}/>
            <div className={"login-container"}>
                <Form>
                    <Image src={"./img/logos/Color.png"}/>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter username"
                            value={username}
                            required
                            onChange={e => setUsername(e.currentTarget.value)}
                            onKeyUp={handleKeyUp}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            required
                            onChange={e => setPassword(e.currentTarget.value)}
                            onKeyUp={handleKeyUp}
                        />
                    </Form.Group>
                    <Button className={"btn btn-color btn-color-black"} disabled={!username || !password} onClick={login}>Log in</Button>
                </Form>
            </div>
        </div>
    );
}

export default Login;