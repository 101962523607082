import React, {useContext} from "react";
import {Button, Modal} from "react-bootstrap";

import {ToasterContext} from "../../../../stores/toaster";
import PropertyService from "../../../../service/PropertyService";

import ModalConfirmDeleteProps from "./ModalConfirmDelete.types";
import "./ModalConfirmDelete.scss";

function ModalConfirmDelete({show, setShow, property, getProperties}: ModalConfirmDeleteProps): JSX.Element {
    const { dispatch } = useContext(ToasterContext);

    const handleClose = () => {
        setShow(false);
    };

    const deleteProperty = async () => {
        setShow(false);
        if (property) {
            try {
                await PropertyService.deleteProperty(property.id);
                dispatch({
                    message: `Deleted property with reference ${property.ref} successfully!`,
                    type: "success"
                });
                getProperties();
            } catch (error) {
                dispatch({message: `${error.message || error.error}`, type: "error"});
            }
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>Are you sure deleting property with reference: {property?.ref}?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button className={"btn btn-color btn-color-blue"} onClick={deleteProperty}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalConfirmDelete;