import React, {useEffect, useRef, useState} from "react";
import {Button, Image, Modal } from "react-bootstrap";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/all";

import Miniatures from "./Miniatures/Miniatures";

import ModalImagesPropertyProps from "./ModalImagesProperty.types";
import "./ModalImagesProperty.scss";

function ModalImagesProperty({show, handleClose, images }: ModalImagesPropertyProps): JSX.Element {
    const [index, setIndex] = useState<number>(0);
    const [pxToMoveCarousel, setPxToMoveCarousel] = useState<number>(0);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    });

    useEffect(() => {
        setPxToMoveCarousel(0);
    }, [index]);


    const moveToRight = (): void => {
        setPxToMoveCarousel(pxToMoveCarousel - 70);
        if (index === images.length - 1) setIndex(0);
        else setIndex(index + 1);
    };

    const moveToLeft = (): void => {
        setPxToMoveCarousel(pxToMoveCarousel + 70);
        if (index === 0) setIndex(images.length - 1);
        else setIndex(index - 1);
    };

    const handleOnKeyPressed = (event: any): void => {
        if (event.key === "ArrowLeft") moveToLeft();
        else if (event.key === "ArrowRight") moveToRight();
    };

    const renderButton = (moveTo: "left" | "right"): JSX.Element => {
        const icon: JSX.Element = (moveTo === "left") ? <IoIosArrowBack/> : <IoIosArrowForward/>;
        return (
            <div className={`arrows ${moveTo === "left" ? "" : "right-position"}`}>
                <Button disabled={images.length === 1} className={"btn btn-link btn-link-blue"} onClick={(moveTo === "left") ? moveToLeft : moveToRight}>
                    {icon}
                </Button>
            </div>
        );
    };

    return (
        <Modal id={"modal-images-property"} show={show} onHide={handleClose} animation={true}>
            <Modal.Header closeButton>
                <input ref={inputRef} style={{ opacity: 0 }} onKeyDown={e => handleOnKeyPressed(e)} />
            </Modal.Header>
            <Modal.Body>
                <div className={"modal-container"}>
                    {renderButton("left")}
                    <div className={"image-block"}>
                        <Image src={images[index]} />
                        <Miniatures index={index} setIndex={setIndex} pxToMoveCarousel={pxToMoveCarousel} images={images}/>
                    </div>
                    {renderButton("right")}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalImagesProperty;