import React, {useCallback, useEffect, useState} from "react";
import { Image } from "react-bootstrap";

import { MiniaturesProps, MiniatureItem } from "./Miniatures.types";
import "./Miniatures.scss";

function Miniatures({index, setIndex, pxToMoveCarousel, images }: MiniaturesProps): JSX.Element {

    const cropImages = useCallback((): MiniatureItem[] => {
        let miniatures: MiniatureItem[] = [];
        let prevImages: number = (images.length > 5) ? index - 2 : 0;
        let nextImages: number = (images.length > 5) ? index + 2 : images.length - 1;

        for (let i: number = prevImages; i <= nextImages; i++) {
            let index = i;
            let image = images[i];
            if (i < 0) {
                index = images.length + i;
                image = images[index];
            }
            if (i >= images.length) {
                index = i - images.length;
                image = images[index];
            }

            miniatures.push({image, index});
        }

        return miniatures;
    }, [index, images]);

    const [miniatures, setMiniatures] = useState<MiniatureItem[]>(cropImages);

    useEffect(() => {
        setMiniatures(cropImages);
    }, [cropImages]);

    return (
        <div className={"miniatures"}>
            <p>{index + 1} / {images.length}</p>
            <ul className={"carousel-list"}>
                {miniatures.map(miniature => (
                    <li
                        key={miniature.index}
                        className={(index === miniature.index) ? "selected" : ""}
                        onClick={() => setIndex(miniature.index)}
                        style={{transform: `translate3d(${pxToMoveCarousel}px, 0px, 0px)`}}
                    >
                        <Image src={miniature.image}/>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Miniatures;