import React, {useEffect, useState} from "react";
import {Button, Table} from "react-bootstrap";
import {
    BsFillTrashFill,
    FaBath, FaExpand, FaEye,
    FaHome, FaPlusCircle, FaSortNumericDown, FaSortNumericUp,
    IoIosBed,
    RiEdit2Line
} from "react-icons/all";

import ModalConfirmDelete from "./ModalConfirmDelete/ModalConfirmDelete";
import {Image} from "../../common/index";
import {IProperty} from "../../../domain";
import {OrderBy, SortBy} from "../../../domain/enums";
import {parseNumberWithCommas} from "../../../utils/utils";

import TablePropertyProps from "./PropertyTable.types";
import "./PropertyTable.scss";
import ModalViewer from "../Editor/ModalViewer";

function PropertyTable({properties, criteria, setCriteria, ...props }: TablePropertyProps): JSX.Element {

    const headers :any[] = [
        {
            title:"MAIN IMAGE",
        }, {
            title:  "REFERENCE",
        }, {
            title: "STATUS",
        }, {
            title: <IoIosBed/>,
            action: SortBy.BEDROOMS,
        }, {
            title: <FaBath/>,
            action: SortBy.BATHROOMS,
        }, {
            title: <FaExpand/>,
            action: SortBy.PLOTSIZE,
        }, {
            title: <FaHome/>,
            action: SortBy.BUILDSIZE,
        }, {
            title: "€",
            action: SortBy.PRICE,
        }, {
            title: "ACTIONS",
        }
    ];
    const [showViewModal, serShowViewModal] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [propertySelected, setPropertySelected] = useState<IProperty| null>(null);
    const [sortBy, setSortBy] = useState<SortBy | undefined>(criteria.field);
    const [orderBy, setOrderBy] = useState<OrderBy | undefined>(criteria.order);

    useEffect(() => {
        setSortBy(criteria.field);
        setOrderBy(criteria.order);
    }, [criteria]);

    const goToEdit = (property: IProperty): void => {
        props.history.push(`/property/edit/${property.id}`, {state: {property}});
    };
    
    const changeOrderByField = (field: SortBy): void => {
        let orderByType = OrderBy.ASC;
        if (field === sortBy && orderBy === OrderBy.ASC) orderByType = OrderBy.DESC;

        setSortBy(field);
        setOrderBy(orderByType);
        setCriteria ({ ...criteria, page: 1, field, order: orderByType });
    };

    const renderActionButton = (property: IProperty): JSX.Element => (
        <div className={"buttons-container"}>
            <Button className={"btn btn-color btn-color-grey"} onClick={() => {setPropertySelected(property); serShowViewModal(true); }}><FaEye/></Button>
            <Button className={"btn btn-color btn-color-blue"} onClick={() => goToEdit(property)}><RiEdit2Line/></Button>
            <Button
                className={"btn btn-color btn-color-red"}
                onClick={() => {setPropertySelected(property); setShowDelete(true); }}
            >
                <BsFillTrashFill/>
            </Button>
        </div>
    );

    const renderPropertyRow = (property: IProperty, i: number): JSX.Element => (
        <tr key={i}>
            <td>
                <div className={"container-img"}>
                    <Image
                        src={property.images[0]}
                        placeholder={"favicon.ico"}
                        fallback={"favicon.ico"}
                        alt={"main image property"}
                        className={"main-image"}
                    />
                </div>
            </td>
            <td><p>{property.ref}</p></td>
            <td className={`label-${property.sold ? "sold" :"sale"}`}><p>{property.sold ? "SOLD" : "FOR SALE"}</p></td>
            <td><p>{property.bedrooms}</p></td>
            <td><p>{property.bathrooms}</p></td>
            <td><p>{parseNumberWithCommas(property.plotSize, "en")} m<sup>2</sup></p></td>
            <td><p>{parseNumberWithCommas(property.buildSize, "en")} m<sup>2</sup></p></td>
            <td><p>{parseNumberWithCommas(property.price, "en")} €</p></td>
            <td>{renderActionButton(property)}</td>
        </tr>
    );

    return (
        <div>
            <ModalConfirmDelete show={showDelete} setShow={setShowDelete} property={propertySelected} {...props} />
            {propertySelected ? <ModalViewer showModal={showViewModal} setShowModal={serShowViewModal} property={propertySelected} setProperty={() => {}}/> : ''}
            <Table id={"table-properties"} responsive hover>
                <thead>
                    <tr>
                        {headers.map((header, i) => {
                            if (!header.action) return <th key={i}>{header.title}</th>;
                            else {
                                let icon: JSX.Element | null = null;
                                if (sortBy === header.action) {
                                 if (orderBy === OrderBy.ASC) icon = <FaSortNumericDown />;
                                 else icon = <FaSortNumericUp />;
                                }
                                return (
                                    <th key={i} onClick={() => changeOrderByField(header.action)}>
                                        {header.title} {icon}
                                    </th>
                                );
                            }
                        })}
                    </tr>
                </thead>
                <tbody>
                {properties.map((property, i) => (
                    renderPropertyRow(property, i)
                ))}
                <tr className={"tr-btn"}>
                    <td>
                        <Button
                            className={"btn btn-color btn-color-green"}
                            onClick={() => props.history.push("/property/create")}
                        >
                            <FaPlusCircle/> New House
                        </Button>
                    </td>
                    <td/>
                    <td/>
                </tr>
                </tbody>
            </Table>
        </div>
    );
}

export default PropertyTable;