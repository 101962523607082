import Cookies from "universal-cookie";

const cookies = new Cookies();

export function getTokenCookie() {
  return cookies.get("token") || null;
}

export function setTokenCookie(value: string) {
  cookies.set("token", value);
}

export function removeTokenCookie() {
  cookies.remove("token");
  window.location.replace("/login");
}