import React from "react";
import {Button} from "react-bootstrap";
import {FaMinusCircle} from "react-icons/all";

import {Image} from "../../../../common";

import ImageListEditorProps from "./ImagesListEditor.types";
import "./ImagesListEditor.scss";

function ImageListEditor({images, setImages}: ImageListEditorProps): JSX.Element {
    const placeholder = document.createElement('li');
    placeholder.className = 'placeholder';
    let dragged: any;
    let over: any;

    const getLiElementByTarget = (element: any) => {
        const childs = document?.getElementById('imageList')?.getElementsByTagName('LI');
        if (childs) {
            for (let i = 0; i < childs.length; i++) {
                if (childs[i].contains(element)) return childs[i];
            }
        }
    };

    const dragStart = (e:any) => {
        dragged = e.currentTarget;
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/html', dragged);
    };

    const dragEnd = () => {
        dragged.style.display = 'block';
        dragged.parentNode.removeChild(placeholder);

        // update state
        const from = parseInt(dragged.id, 10);
        let to = parseInt(over.id, 10);
        if (from < to) to -= 1;
        if (to === images.length) to = images.length + 1;

        images.splice(to, 0, images.splice(from, 1)[0]);
        setImages(images);
    };

    const dragOver = (e:any) => {
        e.preventDefault();
        dragged.style.display = 'none';
        if (e.target.className === 'placeholder') return;
        over = getLiElementByTarget(e.target);
        document?.getElementById('imageList')?.insertBefore(placeholder, over);
    };

    const removeImage = (i: number) => {
        const imagesList = images;
        imagesList.splice(i, 1);
        setImages(imagesList);
    };

    const renderMediaItems = () => {
        return (
            images.map((image, i) => (
                    <li
                        key={i}
                        id={i.toString()}
                        draggable="true"
                        onDragEnd={dragEnd}
                        onDragStart={dragStart}
                        className={"image-property"}
                    >
                        <div className={"image-wrap"}>
                            <Image
                                src={image}
                                placeholder={"favicon.ico"}
                                fallback={"favicon.ico"}
                                alt={"image"}
                                className={"main-image"}
                            />
                            <Button className={"btn btn-link btn-link-red btn-remove"} onClick={() => removeImage(i)}><FaMinusCircle /></Button>
                            <label className={"drag-text"}>Drag image ({i + 1})</label>
                        </div>
                    </li>
            ))
        );
    }

    return (
        <div id={"imageList"} onDragOver={dragOver}>
            {renderMediaItems()}
        </div>
    );

}

export default ImageListEditor;