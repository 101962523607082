import Axios from "axios";

import { getHeaders, handleError } from "./CommonService";
import  {IEncodedFile } from "../domain";

const BASE_URL: string = `${process.env.REACT_APP_STORAGE_URL}`;
const endpoints = {
    uploadImage: () => `${BASE_URL}/create`,
};


const uploadImage = (data: IEncodedFile): Promise<string> => {
    return Axios.post(endpoints.uploadImage(), data, {headers: getHeaders()})
        .then(res => res.data.url)
        .catch(handleError);
};

const exportedObject = {
    uploadImage
};
export default  exportedObject;
