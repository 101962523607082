import React from "react";
import {Col, Form, InputGroup} from "react-bootstrap";

import PropertyEditorProps from "../PropertyEditor.types";
import "./HouseLayout.scss";

function HouseLayout({property, setProperty, viewer}: PropertyEditorProps): JSX.Element {

    return (
        <div id={"house-layout"}>
            <h3>House Layout</h3>
            <hr/>
            <Form>
                <Form.Row>
                    <Form.Group as={Col} xs={"12"} sm={"6"} md={"4"}>
                        <Form.Label>BEDROOMS</Form.Label>
                        <Form.Control
                            disabled={viewer}
                            type="number"
                            min={0}
                            name="bedrooms"
                            value={property?.bedrooms}
                            onChange={e => setProperty({...property, bedrooms: parseInt(e.currentTarget.value, 10)})}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={"12"} sm={"6"} md={"4"}>
                        <Form.Label>BATHROOMS</Form.Label>
                        <Form.Control
                            disabled={viewer}
                            type="number"
                            min={0}
                            name="bathrooms"
                            value={property?.bathrooms}
                            onChange={e => setProperty({...property, bathrooms: parseInt(e.currentTarget.value, 10)})}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} xs={"12"} sm={"6"} md={"4"}>
                        <Form.Label>PLOT SIZE</Form.Label>
                        <InputGroup className="mb-2">
                            <Form.Control
                                disabled={viewer}
                                type="number"
                                min={0}
                                name="plot-size"
                                value={property?.plotSize}
                                onChange={e => setProperty({...property, plotSize: parseInt(e.currentTarget.value, 10)})}
                            />
                            <InputGroup.Prepend>
                                <InputGroup.Text>m<sup>2</sup></InputGroup.Text>
                            </InputGroup.Prepend>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} xs={"12"} sm={"6"} md={"4"}>
                        <Form.Label>BUILD SIZE</Form.Label>
                        <InputGroup className="mb-2">
                            <Form.Control
                                disabled={viewer}
                                type="number"
                                min={0}
                                name="build-size"
                                value={property?.buildSize}
                                onChange={e => setProperty({...property, buildSize: parseInt(e.currentTarget.value, 10)})}
                            />
                            <InputGroup.Prepend>
                                <InputGroup.Text>m<sup>2</sup></InputGroup.Text>
                            </InputGroup.Prepend>
                        </InputGroup>
                    </Form.Group>
                </Form.Row>
            </Form>
        </div>
    );
}

export default HouseLayout;