import React from "react";
import {Col, Button, Form} from "react-bootstrap";


import PropertyEditorProps from "../PropertyEditor.types";
import "./Status.scss";

function Status({property, setProperty, viewer}: PropertyEditorProps): JSX.Element {

    return (
        <div id={"status"}>
            <h3>Status</h3>
            <hr/>
            <Form>
                {viewer ?
                    <Form.Row>
                        <Form.Group as={Col} xs={"12"} sm={"6"} md={"4"}>
                            <Button className={`btn btn-color btn-color-${property.sold ? "red" : "green"}`}>
                                {property.sold ? "SOLD" : "FOR SALE"}
                            </Button>
                        </Form.Group>
                    </Form.Row>
                :
                    <Form.Row>

                        <Button
                            className={`btn btn-${!property.sold ? "color" : "bordered"} btn-${!property.sold ? "color" : "bordered"}-green`}
                            onClick={() => setProperty({...property, sold: false})}>FOR SALE</Button>
                        <Button
                            className={`btn btn-${property.sold ? "color" : "bordered"} btn-${property.sold ? "color" : "bordered"}-red`}
                            onClick={() => setProperty({...property, sold: true})}>SOLD</Button>

                    </Form.Row>
                }
            </Form>
        </div>
    );
}

export default Status;