import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button, Col, Dropdown, DropdownButton, Form} from "react-bootstrap";
import {FaPlusCircle} from "react-icons/all";

import {CreateModal} from "../../../Town";
import {ITown} from "../../../../domain";
import TownService from "../../../../service/TownService";
import {ToasterContext} from "../../../../stores/toaster";
import {LoadingContext} from "../../../../stores/loading";

import PropertyEditorProps from "../PropertyEditor.types";
import "./Location.scss";

function Location({property, setProperty, viewer}: PropertyEditorProps): JSX.Element {
    const { dispatch } = useContext(ToasterContext);
    const { dispatch: loader } = useContext(LoadingContext);

    const [towns, setTowns] = useState<ITown[]>([]);
    const [showCreateAlert, setShowCreateAlert] = useState<boolean>(false);

    const getTowns = useCallback(async () => {
        try {
            const towns: ITown[] = await TownService.getAllTowns();
            setTowns(towns);
        } catch (error) {
            dispatch({message: `${error.message || error.error}`, type: "error"});
            setTowns([]);
        }
    }, [dispatch]);

    useEffect(() => {
        loader({ show: true });
        getTowns().then(() => loader({ show: false }));
    }, [loader, getTowns, showCreateAlert]);

    useEffect(() => {}, [towns]);

    return (
        <div id={"location"}>
            <h3>Location</h3>
            <hr/>
            <CreateModal show={showCreateAlert} setShow={setShowCreateAlert} getTowns={getTowns} />
            <Form>
                <Form.Row>
                    <Form.Group as={Col} xs={"12"} sm={"6"} md={"4"}>
                        <Form.Label>LATITUDE</Form.Label>
                        <Form.Control
                            disabled={viewer}
                            id="town-lat"
                            type="number"
                            placeholder="0"
                            value={property?.latitude || 0}
                            onChange={e => setProperty({ ...property, latitude: parseInt(e.currentTarget.value, 10) || 0 })}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={"12"} sm={"6"} md={"4"}>
                        <Form.Label>LONGITUDE</Form.Label>
                        <Form.Control
                            disabled={viewer}
                            id="town-long"
                            type="number"
                            placeholder="0"
                            value={property?.longitude || 0}
                            onChange={e => setProperty({ ...property, longitude: parseInt(e.currentTarget.value, 10) || 0 })}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} xs={"12"} sm={"6"} md={"4"}>
                        <Form.Label>TOWN SELECTED</Form.Label>
                        <DropdownButton disabled={viewer} id="dropdown-basic-location" title={property?.town?.name || "None"}>
                            {towns.map((town, i) => (
                                <Dropdown.Item
                                    key={i}
                                    className={town.name === property?.town?.name ? "active" : ""}
                                    onClick={() => setProperty({...property, town})}
                                >
                                    {town.name}
                                </Dropdown.Item>
                            ))}
                            <Dropdown.Divider/>
                            <Dropdown.Item>
                                <Button
                                    className={"btn btn-green"}
                                    onClick={() => setShowCreateAlert(true)}
                                >
                                    <FaPlusCircle/> New Town
                                </Button>
                            </Dropdown.Item>
                        </DropdownButton>
                    </Form.Group>
                </Form.Row>
            </Form>
        </div>
    );
}

export default Location;